import config from '@/config'

export function mountUrlWP(url: string): string{
  return config.getUrlBase() + url;
}

export function mountUrlApiTransparencia(url: string): string{
  return config.getUrlApiTransparencia() + url;
}

export default{
  mountUrlWP,
}