import { Component, Vue } from 'vue-property-decorator'
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class BtnGoTop extends Vue {
  displayBtnGoTop = 'none'

	mounted() {
		window.onscroll = () => {this.scrollFunction()};	
	}

	scrollFunction() {
		const limit = 150;
		// document.body.scrollTop > limit || 
		if( document.documentElement.scrollTop > limit ) {
			this.displayBtnGoTop = 'block';
		} 
		else {
			this.displayBtnGoTop = 'none';
		}
	}


	clickGoTop(){
		window.scrollTo(0,0);
	}
}
  