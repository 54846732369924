import { Component, Vue } from 'vue-property-decorator';

import BtnGoTop from '@/components/btnGoTop/btnGoTop.vue';

import {
  NavBar,
  Footer,
} from '@/components/base';

@Component({
  components: {
    NavBar,
    Footer,
    BtnGoTop,
  },
})
export default class Basic extends Vue {
  
}